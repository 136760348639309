/* ==========================================================================
Formidable
========================================================================== */
.formidable {
  @extend
    .mx-0,
    .position-relative,
    .w-100;

  @include make-row();

  /* ==========================================================================
  Form
  ========================================================================== */
  form[name="formidable_form"] {
    @extend
      .bg-white,
      .px-1,
      .px-sm-3,
      .py-3,
      .py-sm-5,
      .rounded,
      .w-100;

    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  }

  /* ==========================================================================
  Required
  ========================================================================== */
  .required {
    @extend .position-relative;

    top: -10px;
  }

  /* ==========================================================================
  Buttons
  ========================================================================== */
  .btn {
    @extend
      .btn-lg,
      .btn-success,
      .m-0,
      .rounded;

    color: $white !important;
    padding-left: $btn-padding-x !important;
    padding-right: $btn-padding-x !important;
  }

  .buttons {
    @extend .col;

    .btn {
      @extend
        .w-100;
    }
  }

  /* ==========================================================================
  Elements
  ========================================================================== */

  div[class^="captcha"] {
    @extend .d-none;
  }

  input:not([type="checkbox"]) {
    @extend
      .my-0;

    font-weight: 300;
    height: 50px;

    &::placeholder {
      @extend .text-theme-primary;
    }
  }

  .input {
    @extend .mb-2;
  }

  label {
    @extend
      .d-block,
      .my-0,
      .text-theme-primary,
      .text-uppercase;

    @include font-size(22);

    font-family: $font-family-1;

    p {
      color: $black;
      font-family: $font-family-2;
      text-transform: none;

      @include font-size(16);

      a {
        color: $black;
        font-family: $font-family-2 !important;
      }
    }
  }

  textarea {
    @extend .text-theme-primary;

    font-weight: 300;
    height: 150px;
    resize: none;

    &::placeholder {
      @extend .text-theme-primary;
    }
  }

  /* ==========================================================================
  Errors
  ========================================================================== */
  .has-error {
    .checkbox,
    .radio {
      label {
        color: $danger;
      }
    }

    .error {
      @extend .is-invalid;
    }
  }

  .text-danger {
    @extend .d-none;
  }

  /* ==========================================================================
  Loader
  ========================================================================== */
  .please_wait_loader {
    @extend
      .mt-1,
      .text-center,
      .w-100;
  }

  /* ==========================================================================
  Formidable column
  ========================================================================== */
  .formidable_column {
    @extend .py-0;

    @include make-col-ready();

    &.width-12 {
      @extend .col-12;
    }

    &.width-6 {
      @extend .col-md-6;

      float: left;
    }

    &.width-4 {
      @extend .col-md-4;
    }

    &.width-3 {
      @extend .col-md-3;
    }
  }

  /* ==========================================================================
  Formidable column inner
  ========================================================================== */
  .formidable_column_inner {
    .element {
      @include make-row();
    }
  }

  /* ==========================================================================
  Formidable message
  ========================================================================== */
  .formidable_message {
    @extend .d-none;
  }

  /* ==========================================================================
  Formidable row
  ========================================================================== */
  .formidable_row {
    clear: none;
    overflow: visible;

    .element {
      clear: none;
      overflow: visible;
    }
  }

  /* ==========================================================================
  Input type checkbox & radio
  ========================================================================== */
  /* stylelint-disable */
  .input-checkbox,
  .input-radio {
    .custom-control {
      @extend .custom-checkbox;
    }

    input[type="checkbox"],
    input[type="radio"] {
      @extend .custom-control-input;
    }

    .custom-control-label {
      p {
        @extend
          .mb-0,
          .ml-1;
      }

      a {
        @extend .text-theme-primary;

        font-family: $font-family-1;
        text-decoration: underline;
      }

      &::after {
        height: 20px;
        width: 20px;
      }

      &::before {
        @extend .rounded;

        background: none !important;
        border: 1px solid $gray-400 !important;
        box-shadow: none !important;
        height: 20px;
        width: 20px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $theme-primary !important;
      border-color: $theme-primary !important;
    }

    &.has-error {
      .custom-control-label {
        &::before {
          border-color: $danger !important;
        }
      }
    }
  }
  /* stylelint-enable */
}
