/* ==========================================================================
Card expand
========================================================================== */
.card__expand {
  .show__more,
  .show__less {
    @extend .pt-5;

    cursor: pointer;
    display: inline-block;
    bottom: 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
    z-index: 2;

    p {
      @extend
        .h5,
        .mb-0;
    }

    .fa-chevron-down,
    .fa-chevron-up {
      color: $theme-primary;

      @include font-size(24);
    }
  }

  .show__more {
    position: absolute;
  }
}

.card__expand-height {
  max-height: 1000px;
  overflow: hidden;

  &::after {
    content: "";
    background: linear-gradient(0deg, rgba($white, 1) 0%, rgba($white, 0) 50%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
  }
}
