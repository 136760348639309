/* ==========================================================================
Table
========================================================================== */
.table-mobile {
  @extend .mb-4;

  td {
    width: 50%;
  }
}
