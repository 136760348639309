/* ==========================================================================
Call to action
========================================================================== */
.cta__block {
  @extend .mb-3;

  border-radius: 10px;
  position: relative;
  height: 360px;

  img {
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.cta__content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.cta__inner {
  @extend .p-2;

  width: 75%;
}

.cta__title {
  h3 {
    color: $white;
    line-height: 32px;
  }
}

.cta__subtitle {
  p {
    @extend .h5;

    color: rgba($white, 0.6);
    font-family: $font-family-1;
    line-height: 20px;
  }
}

.cta__button {
  @extend .mt-1;
}

@include media-breakpoint-up(xl) {
  .cta__inner {
    width: 64%;
  }

  .cta__title {
    h3 {
      @include font-size(52);

      line-height: 42px;
    }
  }

  .cta__subtitle {
    p {
      line-height: 26px;
    }
  }
}
