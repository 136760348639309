/* ==========================================================================
Newsletter
========================================================================== */
.newsletter__form {
  @extend
    .pb-1,
    .pb-md-3;

  .formidable {
    form {
      background-color: transparent !important;
      color: $white;
      padding: 0 !important;

      h4,
      label {
        color: $white !important;
      }

      .formidable_column {
        @extend .px-0;
      }

      .formidable_row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
      }

      .e-mail-227 {
        width: 100%;
      }

      input[type=email] {
        border-radius: 0;
        border: 0;
        background-color: rgba($white, 0.4);
        color: $white !important;
      }

      ::placeholder {
        color: rgba($white, 0.4) !important;
      }

      .btn {
        @extend .btn-white;

        line-height: 1.2;
        padding-left: 20px !important;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .newsletter__form {
    .formidable {
      form {
        .e-mail-227 {
          width: 80%;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .newsletter__form {
    .formidable {
      form {
        .e-mail-227 {
          width: 71%;
        }
      }
    }
  }
}
