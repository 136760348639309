/* ==========================================================================
Minimal
========================================================================== */
.ccm-block-page-list-wrapper.minimal {
  .list-group {
    padding-top: 5px;
  }

  .list-group-item {
    @extend .px-0;

    background-color: transparent;
    border: 0;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .list-group-date {
    @extend .mr-1;

    @include font-size(16);
    @include transition();

    color: rgba($black, 0.5);
  }

  .list-group-title {
    @include font-size(18);

    font-family: $font-family-1;
    font-weight: 700;
  }

  .fal {
    @include font-size(18);
    @include transition();

    color: $theme-primary;
    position: absolute;
    right: 0;
    top: 4px;
    z-index: 1;
  }

  .list-group-link {
    @extend
      .d-block,
      .pr-3;

    @include font-size(0);
    @include transition();

    color: $theme-primary;
    position: relative;
    text-transform: uppercase;

    &:focus,
    &:hover {
      text-decoration: none;

      .list-group-title {
        opacity: 0.5;
      }

      .fal {
        opacity: 0.5;
        right: 10px;
      }
    }
  }

  .ccm-block-page-list-pagination {
    @extend .mt-1;

    .btn {
      @extend .btn-block;

      @include font-size(16);

      color: rgba($theme-primary, 0.2);
      text-transform: uppercase;

      &:focus,
      &:hover {
        color: $theme-primary;
      }
    }
  }

  .ccm-block-page-list-see-all {
    align-items: center !important;
    justify-content: center !important;
    margin-top: $spacer !important;
    opacity: 0.5;
    text-align: center !important;
  }
}
