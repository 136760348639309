/* ==========================================================================
Plan details
========================================================================== */
.plan__details-block {
  @extend .p-3;
}

.plan__details-title {
  @extend .mb-3;

  text-align: center;
}

.plan__details-col {
  @extend
    .px-1,
    .mb-2;
}

.plan__details-card {
  @extend
    .p-2;

  background-color: rgba($theme-primary, 0.05);
  height: 100%;

  p {
    @extend .mb-0;
  }
}

.details__card-title {
  @extend
    .pl-3,
    .mb-1;

  position: relative;

  .card__title {
    font-weight: 900;
  }

  .card__icon {
    position: absolute;
    left: 0;
  }

  .fa-check-square {
    color: $green;

    @include font-size(24);
  }
}

.details__card-text {
  color: rgba($black, 0.5);
}
