/* ==========================================================================
Hero
========================================================================== */
.hero {
  overflow: hidden;
  position: relative;
}

/* ==========================================================================
Hero block
========================================================================== */
.hero--block {
  &::before {
    @extend .w-100;

    background-color: rgba($theme-primary, 0.2) !important;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 2;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .row {
    @extend .align-items-center;
  }
}

/* ==========================================================================
Hero image
========================================================================== */
.hero--image {
  @extend .h-100;
  @extend .w-100;

  position: absolute;
  top: 0;
  z-index: -1;

  picture {
    @extend .d-block;
    @extend .h-100;
  }

  img {
    @extend .h-100;
    @extend .w-100;

    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* ==========================================================================
Hero image
========================================================================== */
.hero--images {
  @extend .h-100;
  @extend .w-100;

  position: absolute;
  top: 0;
  z-index: 1;

  .owl-carousel,
  .owl-stage-outer,
  .owl-stage,
  .owl-item {
    @extend .h-100;
  }
}

/* ==========================================================================
Hero title
========================================================================== */
.hero--title {
  h1 {
    @extend .text-white;

    overflow-wrap: break-word;
  }

  p {
    @extend .h1;
  }
}

/* ==========================================================================
Hero subtitle
========================================================================== */
.hero--subtitle {
  h2 {
    @extend
      .h3,
      .text-white;

    text-transform: uppercase;
  }

  p {
    @extend .h2;
  }
}

/* ==========================================================================
Hero text
========================================================================== */
.hero--text {
  @extend .mt-lg-1;

  p {
    @include font-size(16);
  }
}

/* ==========================================================================
Hero buttons
========================================================================== */
.hero--buttons {
  @extend .mt-3;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0;

  .btn {
    @extend .mb-2;
    @extend .mb-sm-0;

    min-width: 160px;
  }

  .btn + .btn {
    @extend .ml-sm-3;
  }

  .btn + .btn + .btn {
    @extend .mt-sm-2;
  }

  .btn--id-0 {
    @extend .btn-white;
  }

  .btn--id-1 {
    @extend .btn-outline-white;
  }

  .btn--id-2 {
    @extend .btn-theme-secondary;
  }
}

@include media-breakpoint-up(sm) {
  .hero--buttons {
    flex-direction: row;
  }
}

/* ==========================================================================
Hero color (dark / light)
========================================================================== */
.hero--color-dark {
  background-color: rgba($theme-primary, 0.5);

  .hero--title,
  .hero--subtitle,
  .hero--text {
    p {
      color: $body-color;
    }
  }
}

.hero--color-light {
  .hero--inner {
    background-color: rgba($theme-primary, 0.5);
  }

  .hero--title,
  .hero--subtitle,
  .hero--text {
    p {
      color: $white;
    }
  }
}

/* ==========================================================================
Hero align (center / left / right)
========================================================================== */
.hero--align-center {
  @extend .text-md-center;

  .breadcrumb {
    @extend .d-inline-flex;
  }

  .author {
    @extend .d-inline-flex;

    text-align: left !important;
  }

  .row {
    @extend .justify-content-md-center;
  }

  &.hero--color-dark {
    &::before {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 50%, rgba($white, 0) 100%);
    }
  }

  &.hero--color-light {
    &::before {
      background: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 1) 50%, rgba($black, 0) 100%);
    }
  }
}

.hero--align-left {
  @extend .text-md-left;

  .row {
    @extend .justify-content-md-start;
  }

  &.hero--color-dark {
    &::before {
      background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 100%);
    }
  }

  &.hero--color-light {
    &::before {
      background-color: rgba($custom-purple-1, 0.4) !important;
    }
  }
}

.hero--align-right {
  @extend .text-md-right;

  .author {
    @extend .d-inline-flex;
  }

  .row {
    @extend .justify-content-md-end;
  }

  &.hero--color-dark {
    &::before {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 100%);
    }
  }

  &.hero--color-light {
    &::before {
      background: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 1) 100%);
    }
  }
}

/* ==========================================================================
Hero gradient
========================================================================== */
.hero--gradient-hide {
  &::before {
    @extend .d-none;
  }
}

.hero--gradient-show {
  &::before {
    @extend .d-block;
  }
}

/* ==========================================================================
Hero arrow
========================================================================== */
.hero--arrow {
  border-radius: 0;
  bottom: 20px;
  color: $white;
  height: $hero-arrow-size;
  line-height: $hero-arrow-size;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: $hero-arrow-size;
  z-index: 3;

  .fa-arrow-circle-down {
    @include font-size(32);
  }
}

@include media-breakpoint-up(sm) {
  .hero--arrow {
    .fa-arrow-circle-down {
      @include font-size(40);
    }
  }
}

.hero--arrow-hide {
  .hero--arrow {
    @extend .d-none;
  }
}

.hero--arrow-show {
  .hero--arrow {
    @extend .d-block;
  }
}

/* ==========================================================================
Hero height (xs / sm / md / lg / xl / xxl)
========================================================================== */
.hero--height-xs {
  .row {
    min-height: $hero-height-xs;
  }
}

.hero--height-sm {
  .row {
    min-height: $hero-height-sm;
  }
}

.hero--height-md {
  .row {
    min-height: $hero-height-md;
  }
}

.hero--height-lg {
  .row {
    @extend
      .pt-4,
      .pt-md-0;

    min-height: $hero-height-lg;
  }
}

.hero--height-xl {
  .row {
    @extend
      .pt-8,
      .pt-sm-0;

    min-height: $hero-height-xl;
  }
}

.hero--height-xxl {
  .row {
    min-height: $hero-height-xxl;
  }
}

/* ==========================================================================
Hero video
========================================================================== */
.hero__video {
  @extend
    .h-100,
    .w-100;

  position: absolute;
  top: 0;
  z-index: 1;

  video {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: 1;
  }
}

.hero__video-btn {
  @extend
    .mt-2,
    .mt-lg-4;

  font-family: $font-family-1;

  a {
    color: $white;
    text-transform: uppercase;

    @include font-size(24);

    .fa-play-circle {
      @extend
        .mr-1,
        .mr-lg-2;

      @include font-size(26);
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero__video-btn {
    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .fa-play-circle {
        @include font-size(48);
      }

      span {
        margin-top: 5px;
      }
    }
  }
}

.hero__modal {
  background-color: rgba($black, 0.8);

  .modal-content {
    background-color: transparent;
  }

  .hero__modal-close {
    color: $white;
    cursor: pointer;

    .fa-times {
      @include font-size(28);
    }

    &:hover,
    &:focus {
      color: $white !important;
    }
  }

  video {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .hero__modal {
    .hero__modal-close {
      .fa-times {
        @include font-size(48);
      }
    }
  }
}
