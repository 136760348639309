/* ==========================================================================
Buttons
========================================================================== */
.btn {
  @extend .text-uppercase;

  @include font-size(20);

  border-radius: $btn-border-radius;
  font-family: $font-family-1;
  transition: all 0.3s ease-in-out;
}

.btn-white,
.btn-light {
  @extend .text-theme-primary;

  &:focus,
  &:hover {
    @extend .bg-theme-primary;
    @extend .text-white;

    border-color: $theme-primary;
  }
}

.btn-outline-theme-primary {
  color: $theme-primary;
  border: 1px solid $theme-primary;

  &:hover,
  &:focus {
    background-color: rgba($theme-primary, 0.3);
    color: $theme-primary;
  }
}

.btn-outline-white {
  color: $white;
  border: 1px solid $white;

  &:hover,
  &:focus {
    background-color: rgba($white, 0.3);
    color: $white;
  }
}

.btn-reset {
  background-color: $white !important;
  border-color: $white !important;
  color: $theme-primary !important;
  padding-bottom: 4px;
  padding-top: 8px;

  &:focus,
  &:hover {
    color: #8a7865 !important;
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    @include font-size(22);
  }
}

/* ==========================================================================
Custom Block Buttons
========================================================================== */
.block--buttons {
  font-size: 0;

  .btn + .btn {
    @extend .ml-0;
    @extend .ml-sm-2;
    @extend .mt-2;
    @extend .mt-sm-0;
  }
}

@include media-breakpoint-down(xs) {
  .block--buttons {
    .btn {
      width: 100%;
    }
  }
}
