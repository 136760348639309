/* ==========================================================================
Card default
========================================================================== */
.card-default {
  @extend .flex-fill;

  background: none;
  border: 0;
  border-radius: 0;

  .card-img {
    border-radius: 0;
  }

  .card-body {
    @extend .p-0;
  }

  .card-footer {
    @extend .mt-3;
    @extend .p-0;

    background: none;
    border-top: 0;
    border-radius: 0;
  }

  .card-play {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    .btn {
      @extend .p-0;

      @include font-size(75);

      color: rgba($white, 0.65);
      line-height: 1;

      &:focus,
      &:hover {
        color: $white;
      }
    }
  }
}

.card-default-col {
  form {
    width: 100%;
  }
}

.card-default-col-left {
  @extend .pr-lg-3;
}

.card-default-col-right {
  @extend .mt-5;
  @extend .mt-lg-0;
  @extend .pl-lg-3;
}

.card-default-btn {
  @extend
    .btn,
    .mt-3,
    .p-0,
    .text-theme-primary;

  &:focus,
  &:hover {
    @extend .text-black;
  }
}

.card-default-swiper {
  .swiper-pagination {
    @extend
      .mt-2,
      .mt-lg-3;

    position: relative;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin-left: 2px;
      margin-right: 2px;
      background-color: transparent;
      border: 2px solid $theme-primary;
      opacity: 1;

      &:focus {
        outline: 0;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $theme-primary;
      border: 1px solid $theme-primary;
    }
  }
}

/* ==========================================================================
Modal card
========================================================================== */
.modal--card {
  .close {
    background-color: $theme-primary;
    right: 1rem;
    top: 1rem;

    span {
      position: relative;
      top: -3px;
    }

    &:hover {
      color: $white !important;
    }
  }
}
