/* ==========================================================================
Switch language dropdown
========================================================================== */
.switch--language-dropdown {
  position: relative;
  z-index: 2;

  .dropdown-toggle {
    @extend
      .d-flex,
      .ml-lg-1,
      .mr-8,
      .mr-lg-0;

    align-items: center;
    padding: 0.8rem 0.5rem 0.5rem 0;

    @include media-breakpoint-up(lg) {
      padding: 1rem 0 0.5rem 0;
    }

    &:focus,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
    }

    span {
      line-height: 1;
    }

    img {
      height: 16px;
      vertical-align: top;
    }

    .fa-caret-down {
      @extend
        .pl-1,
        .position-relative,
        .text-white;

      top: -3px;
    }

    &::after {
      @extend .d-none;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 1rem;
    }
  }

  .dropdown-menu {
    @extend
      .p-0,
      .shadow-sm;

    border-radius: 0;

    img {
      height: 15px;
    }
  }

  .dropdown-item {
    margin-bottom: 0 !important;

    &.active {
      background-color: transparent;
    }

    span {
      @include font-size(22);
    }

    img {
      @extend .pr-1;
    }
  }
}
