/* ==========================================================================
Packages
========================================================================== */
.packages__container {
  background-color: $theme-primary;
}

.packages__intro {
  h1,
  h2,
  h3,
  h4,
  p {
    color: $white;
  }
}
