/* ==========================================================================
Author Avatar
========================================================================== */
.author__avatar {
  border-radius: 10px;
  height: 80px;
  width: 80px;
  background: $white;
  background-size: 1px 100px !important;
}

/* ==========================================================================
Author Hero
========================================================================== */
.author--hero {
  @extend .mt-3;

  h5,
  p {
    @extend .text-white;
  }
}

/* ==========================================================================
Author Block
========================================================================== */
.author--block {
  @extend .mt-3;

  a {
    color: $theme-primary;

    &:hover {
      text-decoration: none;
    }
  }
}

.author--info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.author--title {
  @extend .mb-2;
}

.author--subtitle {
  color: $gray-500;
  font-family: $font-family-1;
  text-transform: uppercase;
  margin-top: -5px;

  @include font-size(20);
}

/* ==========================================================================
Author detail
========================================================================== */
.author--detail {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .author--inner {
    @extend .p-2;

    box-shadow: 0 3px 8px 5px rgba($black, 0.1);
    border-radius: 10px;
    width: 100%;
  }

  .author__avatar {
    @extend
      .mb-lg-2,
      .mb-xl-0;

    height: 150px;
    width: 150px;
  }
}
