/* ==========================================================================
Employee quotes
========================================================================== */
.employee__quotes {
  @extend
    .py-5,
    .py-lg-8;
}

.employee__quotes-title {
  @extend
    .mb-4,
    .mb-lg-6;

  text-align: center;
}

.employee_quotes-card {
  @extend .mb-3;

  position: relative;

  &::before {
    content: "";
    background: linear-gradient(transparent, rgba($theme-primary, 1));
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .card__image {
    height: 500px;
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .card__inner {
    @extend .p-2;

    position: absolute;
    bottom: 0;
    z-index: 3;
  }

  .card__title {
    h3 {
      color: $white;
      line-height: 42px;
    }
  }

  .card__name,
  .card__job {
    color: $custom-brown-1;
  }

  .card__btn {
    @extend .mt-1;

    opacity: 0;
    height: 0;

    @include transition();
  }

  &:hover,
  &:focus {
    .card__btn {
      height: 55px;
      opacity: 1;
    }
  }
}

.quotes__modal {
  background-color: rgba($black, 0.8);

  .modal-content {
    @extend .py-4;

    background-color: transparent;
    border: 0;
  }

  .video__modal-close {
    color: $white;
    cursor: pointer;
    top: 0;

    .fa-times {
      @include font-size(28);
    }

    &:hover,
    &:focus {
      color: $white !important;
    }
  }

  iframe {
    height: 400px;
  }
}

@include media-breakpoint-up(lg) {
  .quotes__modal {
    iframe {
      height: 600px;
    }
  }
}
