/* ==========================================================================
Cookiebot
========================================================================== */
html {
  #CybotCookiebotDialog {
    font-family: $font-family-1 !important;
    width: calc(100% - 30px);
  }

  #CybotCookiebotDialogHeader,
  #CybotCookiebotDialogPoweredByText,
  #CybotCookiebotDialogPoweredbyCybot {
    @extend
      .d-none;
  }

  #CybotCookiebotDialogBodyContentText,
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    font-family: $font-family-2 !important;
  }

  #CybotCookiebotDialogDetailBodyContentTextAbout {
    p {
      font-family: $font-family-2 !important;
    }
  }

  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    color: $theme-primary !important;

    &::after {
      color: $theme-primary !important;
    }
  }

  .CybotCookiebotDialogNavItemLink {
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .CybotCookiebotDialogBodyLevelButtonDescription {
    text-transform: uppercase;
  }

  #CybotCookiebotDialogFooter {
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      @extend
        .btn,
        .btn-sm,
        .btn-theme-primary,
        .w-auto,
        .px-15;

      font-size: 20px !important;
      text-transform: uppercase;
    }

    #CybotCookiebotDialogBodyButtonDecline,
    #CybotCookiebotDialogBodyLevelButtonCustomize,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      @extend
        .btn,
        .btn-sm,
        .btn-outline-theme-primary,
        .w-auto,
        .px-15;

      font-size: 20px !important;
      text-transform: uppercase;

      &.CybotCookiebotDialogHide {
        @extend
          .d-none;
      }

      .CybotCookiebotDialogArrow {
        @extend
          .d-none;
      }
    }
  }

  #CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $theme-primary;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-color: $theme-primary;
    color: $theme-primary;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: $theme-primary;
  }
}
