/* ==========================================================================
Variables - Must be loaded first
========================================================================== */
@import "_variables";
@import "helpers/_mixins";

/* ==========================================================================
Bootstrap - Must be loaded second
https://getbootstrap.com/
========================================================================== */
@import "~bootstrap/scss/bootstrap";

/* ==========================================================================
Animate.css
https://github.com/daneden/animate.css
========================================================================== */
@import "~animate.css/animate.min.css";

/* ==========================================================================
Hamburgers
https://github.com/jonsuh/hamburgers
========================================================================== */
@import "~hamburgers/_sass/hamburgers/hamburgers";

/* ==========================================================================
Plyr
https://github.com/sampotts/plyr
========================================================================== */
@import "~plyr/dist/plyr.css";

/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
@import "~swiper/css/swiper.css";

/* ==========================================================================
Base
========================================================================== */
@import "base/_fonts";
@import "base/_global";
@import "base/_typography";

/* ==========================================================================
Helpers
========================================================================== */
@import "helpers/_concrete5";
@import "helpers/_cookiebot";

/* ==========================================================================
Layouts (needs to be outsite wrapper)
========================================================================== */
@import "layouts/_navbar-collapse";
@import "layouts/_navbar-dropdown";
@import "layouts/_hrcheck";

/* ==========================================================================
Page wrapper to prevent BS3/BS4 conflict in edit mode
========================================================================== */
.page-wrapper {
  /* ==========================================================================
  Blocks
  ========================================================================== */
  @import "blocks/_cards";
  @import "blocks/_columns-video";
  @import "blocks/_contact";
  @import "blocks/_employee-quotes";
  @import "blocks/_list";
  @import "blocks/_offices";
  @import "blocks/_search";
  @import "blocks/_youtube";
  @import "blocks/_text-block";
  @import "blocks/_text-quotes";

  /* ==========================================================================
  Components
  ========================================================================== */
  @import "components/_author";
  @import "components/_breadcrumb";
  @import "components/_buttons";
  @import "components/_block-list";
  @import "components/_card-swiper";
  @import "components/_calltoaction";
  @import "components/_card-columns";
  @import "components/_columns-one";
  @import "components/_columns-two";
  @import "components/_download";
  @import "components/_filter";
  @import "components/_form-background";
  @import "components/_formidable";
  @import "components/_hamburgers";
  @import "components/_info";
  @import "components/_modal";
  @import "components/_newsletter";
  @import "components/_plan-details";
  @import "components/_plans";
  @import "components/_share-links";
  @import "components/_social-media";
  @import "components/_switch-language";
  @import "components/_table-mobile";
  @import "components/_table";
  @import "components/_video-block";
  @import "components/_video";

  /* ==========================================================================
  Layouts
  ========================================================================== */
  @import "layouts/_footer";
  @import "layouts/_header";
  @import "layouts/_hero";
  @import "layouts/_navbar";
  @import "layouts/_packages";
  @import "layouts/_section";

  /* ==========================================================================
  Page List Plus
  ========================================================================== */
  @import "page-list-plus/_minimal";
  @import "page-list-plus/_page-list-plus";
}

/* ==========================================================================
Modal backdrop
========================================================================== */
.modal-backdrop.show {
  z-index: 520;
}
