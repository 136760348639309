/* ==========================================================================
Navbar collapse
========================================================================== */
.navbar-collapse {
  z-index: 1;

  @extend
    .align-items-center,
    .d-flex,
    .pl-2,
    .pl-lg-0,
    .pr-2,
    .pr-lg-0;

  @include transition();
}

@include media-breakpoint-down(md) {
  .navbar-collapse {
    background-color: $theme-primary;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    visibility: hidden;
  }

  .navbar--open {
    .navbar-collapse {
      transform: translateX(0);
      visibility: visible;
    }
  }
}
