/* ==========================================================================
Breadcrumb Light
========================================================================== */
.breadcrumb-light {
  .breadcrumb-item,
  .breadcrumb-link {
    color: $white;
  }
}

/* ==========================================================================
Breadcrumb Dark
========================================================================== */
.breadcrumb-dark {
  .breadcrumb-item,
  .breadcrumb-link {
    color: $theme-primary;
  }
}

/* ==========================================================================
Breadcrumb
========================================================================== */
.breadcrumb {
  @extend .mb-3;
  @extend .p-0;

  background: none;

  .breadcrumb-item {
    @extend .text-uppercase;

    @include font-size(20);

    font-family: $font-family-1;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    color: inherit;
  }

  .breadcrumb-link {
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}
