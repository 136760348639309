// .list-wrapper {}

.list {
  padding: 0;
  margin: 0;

  .list__item {
    padding: 15px;
    background-color: rgba($custom-purple-1, 0.08);
    height: 100%;
    width: 100%;

    p {
      @extend .mb-0;

      font-weight: 700;
    }
  }
}
