/* ==========================================================================
Social media
========================================================================== */
.social__icons {
  @extend .mb-3;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.social--media-list {
  @extend .p-0;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .justify-content-lg-end;

  list-style: none;
}

.social--media-item {
  @extend .d-inline-block;

  &:nth-of-type(2) {
    @extend .px-2;
  }
}

.social--media-link {
  @extend .text-white;

  @include font-size(22px);
  @include transition();

  &:hover {
    @extend .text-theme-primary;
  }
}
