/* ==========================================================================
Hamburger
========================================================================== */
.hamburger {
  bottom: 0;
  position: absolute;
  right: $spacer;
  top: 0;
  z-index: $zindex-fixed;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

.hamburger-inner {
  &::before {
    display: none;
  }
}

.hamburger-title,
.hamburger-close {
  @extend .mr-1;
  @extend .position-relative;
  @extend .text-uppercase;
  @extend .text-white;

  @include font-size(20);

  font-family: $font-family-1;
  opacity: 1;
  top: 1px;
  transition: opacity 0.3s ease-in-out;
}

.hamburger-close {
  display: none;
}

.hamburger.is-active {
  display: flex;
  top: 25px;

  .hamburger-title {
    display: none;
  }

  .hamburger-close {
    display: block;
  }
}
