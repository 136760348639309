/* ==========================================================================
Card gray
========================================================================== */
.card-gray {
  @extend
    .p-3;

  background-color: rgba($theme-primary, 0.05);

  .btn {
    color: $theme-primary !important;
    padding: $btn-padding-y $btn-padding-x !important;
  }
}
