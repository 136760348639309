/* ==========================================================================
Card swiper
========================================================================== */
.card__swiper-container {
  @extend .py-lg-5;

  .ccm-block-page-list-pages {
    .col {
      display: block !important;
    }
  }
}

.card__swiper {
  @extend
    .pb-6,
    .pb-lg-0;

  position: relative;
  overflow: visible;

  .swiper-slide {
    height: auto;
  }

  .card {
    @extend .mb-0;

    background-color: transparent;
    border: 0;
    border-radius: 0;
    position: relative;
    height: 550px;

    @include media-breakpoint-up(md) {
      height: 490px;
    }

    &::after {
      background-color: rgba($theme-primary, 0.4) !important;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  .card-img-top {
    @extend .mb-0;

    position: absolute;
    height: 100%;
    width: 100%;
  }

  .card-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .card-body-inner {
    padding-top: 150px;
  }

  .card-footer {
    @extend .pb-2;

    border: 0;
  }

  .card-body,
  .card-footer {
    position: relative;
    z-index: 2;
  }

  .card-title {
    color: $white;
  }
}

@include media-breakpoint-up(sm) {
  .card__swiper {
    .card-body-inner {
      padding-top: 190px;
    }
  }
}

@include media-breakpoint-up(md) {
  .card__swiper {
    .card-body-inner {
      padding-top: 120px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card__swiper {
    .card-body-inner {
      padding-top: 190px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .card__swiper {
    .card-body-inner {
      padding-top: 140px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .card__swiper {
    .card-body-inner {
      padding-top: 190px;
    }
  }
}

.card__swiper-item {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &:hover,
  &:focus {
    text-decoration: none;

    .btn {
      color: $white !important;
    }
  }
}

.card__swiper-btn {
  @extend
    .mt-2,
    .d-none,
    .d-lg-flex;

  position: relative;
  height: 50px;
  margin-left: -10px;

  .card-swiper-next,
  .card-swiper-prev {
    @extend .mr-3;

    color: $theme-primary;
    border: 2px solid $theme-primary;
    position: relative;
    text-align: center;
    height: 50px;
    width: 50px;

    @include transition();

    &::after {
      font-size: 20px;
      font-weight: bold;
      -webkit-text-stroke: 1px $theme-primary;
    }

    &:hover,
    &:focus {
      background-color: rgba($theme-primary, 0.3);
    }
  }
}

.card-swiper-pagination {
  @extend .d-lg-none;

  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination-bullet {
    background-color: rgba($theme-primary, 0.7);
    height: 10px;
    width: 10px;
  }

  .swiper-pagination-bullet-active {
    background-color: $theme-primary;
    height: 14px;
    width: 14px;
  }
}
