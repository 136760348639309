/* ==========================================================================
Plyr
========================================================================== */
button.plyr__control {
  @extend .bg-white;

  svg {
    fill: $theme-primary;
  }
}

.plyr--full-ui {
  input[type=range] {
    color: $theme-primary;
  }
}
