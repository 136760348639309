/* ==========================================================================
Download
========================================================================== */
.form__download {
  @extend .p-xl-2;

  .formidable {
    form {
      border-radius: 10px !important;
      padding: 20px 0 10px 0 !important;

      .formidable_row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
      }

      .e-mail-303 {
        @extend .order-1;

        width: 100%;
      }

      .gdpr-306 {
        @extend
          .order-2,
          .order-xxl-3;
      }

      .flyer-ontvangen-304 {
        @extend
          .order-3,
          .order-xxl-2;
      }

      .btn {
        @extend .btn-theme-primary;

        color: $white !important;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .form__download {
    .formidable {
      form {
        .e-mail-303 {
          width: 64%;
        }
      }
    }
  }
}
