/* ==========================================================================
Concrete5 css fixes for Bootstrap4
========================================================================== */
div.ccm-panel-content ul.nav,
div.ccm-panel-content menu {
  display: block;
}

#ccm-theme-grid-edit-mode-row-wrapper {
  display: flex;
  width: 100%;
}

/* ==========================================================================
Overrides when toolbar is visible
========================================================================== */
.ccm-toolbar-visible {
  body {
    padding-top: $ccm-toolbar-height;
  }

  .navbar {
    top: $ccm-toolbar-height;
  }

  .hero--height-xxl {
    .row {
      min-height: calc(100vh - (#{$navbar-height} + #{$ccm-toolbar-height}));
    }

    .hero--image {
      img {
        height: calc(100vh - (#{$navbar-height} + #{$ccm-toolbar-height}));
      }
    }
  }
}

.ccm-notification-help-launcher {
  top: 110px !important;
}

/* ==========================================================================
Overrides when in edit mode
========================================================================== */
.ccm-edit-mode {
  body {
    @extend .mt-0;

    padding-top: calc(#{$navbar-height} + #{$ccm-toolbar-height});
  }

  .navbar {
    z-index: 0;
  }

  .hero {
    @extend .pt-0;
  }

  @include media-breakpoint-up(lg) {
    body {
      padding-top: calc(100px + #{$ccm-toolbar-height});
    }
  }
}

/* ==========================================================================
Overrides pagination
========================================================================== */
.ccm-pagination-wrapper {
  .pagination {
    border-radius: $border-radius;
    display: flex;
    margin: 0;

    li {
      @extend .page-item;

      &.active {
        @extend .active;
      }

      &.disabled {
        @extend .disabled;
      }

      a,
      span {
        @extend .page-link;

        > span {
          display: none;
        }
      }
    }
  }
}

/* ==========================================================================
ccm-panel-detail visible fix
========================================================================== */
body div.ccm-panel-detail {
  display: block;
}

.ccm-custom-style-container {
  outline: none;

  &:focus {
    outline: none;
  }
}
