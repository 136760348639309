/* ==========================================================================
Dropdown
========================================================================== */
.navbar {
  .dropdown {
    position: relative;
    padding-bottom: 0 !important;

    .btn {
      @extend
        .p-0;

      color: $white;
      margin-top: -15px;

      @include font-size(32);
    }

    &.show {
      @extend
        .mb-0,
        .mb-lg-1;

      .btn {
        margin-top: -20px;
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    @extend
      .m-0,
      .pb-0,
      .pb-lg-1;

    background-color: transparent;
    border: 0;
    border-radius: 0;

    .dropdown-item {
      @extend
        .pl-2,
        .px-lg-0,
        .mb-1,
        .mb-lg-0;

      color: $white;
      font-family: $font-family-1;
      text-transform: uppercase;

      @include font-size(32);

      span {
        position: relative;

        &::after {
          @extend .position-absolute;

          border-bottom: 2px solid $white;
          bottom: 0;
          content: "";
          left: 0;
          opacity: 0;
          right: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }

      &:hover,
      &:focus {
        background-color: transparent;

        span {
          text-decoration: none;

          &::after {
            opacity: 1;
          }
        }
      }

      &.nav-last {
        @extend .mt-0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      text-align: left;

      .dropdown-item {
        @include font-size(24);

        &.active {
          background-color: transparent;
        }
      }
    }

    .dropdown:hover > .dropdown-menu {
      display: block;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar--scroll,
  .no-hero {
    .navbar {
      .dropdown-menu {
        background-color: $white !important;
        box-shadow: 0 4px 4px -4px rgb(0 0 0 / 50%);

        .dropdown-item {
          color: $theme-primary;
          padding-left: 10px !important;
          padding-right: 10px !important;

          span {
            &::after {
              border-bottom: 2px solid $theme-primary;
            }
          }
        }
      }
    }
  }
}
