/* ==========================================================================
Card vacancy category
========================================================================== */
.card.card__vacancy-category {
  @extend
    .mb-3;

  border-radius: 0;
  border: 0;
  min-width: 100%;
  position: relative;

  &::before {
    background-color: rgba($custom-purple-1, 0.6);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .card-img-overlay {
    @extend
      .mt-auto;

    top: auto;
    z-index: 2;
  }

  .card-title,
  .card-text {
    color: $white;
  }

  .card-img {
    @extend
      .m-0;

    height: 300px;
  }

  .stretched-link {
    &::after {
      top: -200px;
    }
  }
}
