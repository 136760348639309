/* ==========================================================================
Filter
========================================================================== */
.filter {
  .input {
    @extend
      .m-0,
      .row;
  }

  .custom-control {
    @extend
      .col-auto,
      .mb-2,
      .pl-0;
  }

  .custom-control-label {
    @extend
      .btn,
      .btn-outline-theme-primary;

    &::after {
      left: 0;
      opacity: 0;
      top: 0;
    }

    &::before {
      left: 0;
      opacity: 0;
      top: 0;
    }

    &.checked {
      @extend
        .bg-theme-primary,
        .text-white;
    }
  }

  .custom-control-input {
    &:checked + label {
      @extend .checked;
    }
  }
}
