/* ==========================================================================
Card columns
========================================================================== */
.card__columns-container {
  @extend
    .pt-4,
    .pb-8,
    .py-md-8;

  position: relative;

  .card__link {
    @extend
      .mb-2;

    display: inline-block;
    width: 100%;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .card__image {
    @extend .mb-2;

    height: 280px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .card__title {
    @extend .mt-1;

    h3 {
      @extend .h4;
    }
  }

  .card__text {
    color: $black;
  }

  .card__btn {
    color: $theme-primary;
    font-family: $font-family-1;
    text-transform: uppercase;

    @include font-size(20);
  }
}

@include media-breakpoint-up(lg) {
  .card__columns-container {
    .card__btn {
      @include font-size(22);
    }
  }
}
