/* ==========================================================================
Global
========================================================================== */
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

/* ==========================================================================
No-hero
========================================================================== */
/* stylelint-disable */
body.no-hero {
  margin-top: $navbar-height;

  @include media-breakpoint-up(lg) {
    margin-top: 100px;
  }
}

body.no-hero,
body.navbar--scroll {
  /* ==========================================================================
  Hamburger
  ========================================================================== */
  .hamburger-title {
    @extend .text-theme-primary;
  }

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    @extend .bg-theme-primary;
  }

  .hamburger.is-active {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      @extend .bg-white;
    }
  }

  /* ==========================================================================
  Navbar
  ========================================================================== */
  .navbar {
    @extend .bg-white;
  }

  @include media-breakpoint-up(lg) {
    /* ==========================================================================
    Navbar-nav
    ========================================================================== */
    .navbar-nav {
      .nav-last {
        .nav-link {
          background-color: $theme-primary !important;
          border-color: $theme-primary !important;
          color: $white !important;

          &:focus,
          &:hover {
            background-color: $white !important;
            color: $theme-primary !important;
          }
        }
      }

      .nav-link {
        color: $theme-primary;

        &::after {
          border-color: $theme-primary;
        }
      }
    }
  }

  /* ==========================================================================
  Search Form Toggle
  ========================================================================== */
  .search__form-toggle {
    @extend .text-theme-primary;
  }

  /* ==========================================================================
  Switch language dropdown
  ========================================================================== */
  .switch--language-dropdown {
    .dropdown-toggle {
      .fa-caret-down {
        @extend .text-theme-primary;
      }
    }

    .dropdown-menu {
      background-color: $white;

      span {
        color: $theme-primary;
      }
    }
  }

  /* ==========================================================================
  Navbar login
  ========================================================================== */
  .navbar__login {
    .navbar__login-link {
      color: $theme-primary;
    }
  }
}

/* ==========================================================================
Navbar-open
========================================================================== */
body.navbar--open {
  .switch--language-dropdown {
    .dropdown-toggle {
      .fa-caret-down {
        color: $white !important;
      }
    }
  }
}

/* ==========================================================================
Navbar-scroll
========================================================================== */
.navbar--scroll {
  .navbar {
    // @extend .bg-theme-primary;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.5);
  }
  // &.no-hero {
  //   .navbar {
  //     @extend .bg-white;
  //   }
  // }
}
/* stylelint-enable */

/* ==========================================================================
Google Recaptcha
========================================================================== */
.grecaptcha-badge {
  visibility: collapse !important;
}

/* ==========================================================================
Lazy
========================================================================== */
img.lazy {
  @include transition();

  opacity: 0;

  &.loaded,
  &.error {
    opacity: 1;
  }

  &:not([src]) {
    visibility: hidden;
  }
}
