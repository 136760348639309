/* ==========================================================================
Modal
========================================================================== */
.modal {
  .close {
    @extend
      .align-self-end,
      .d-flex,
      .px-15,
      .py-1;

    @include transition();

    color: $gray-400;
    opacity: 1;
    position: absolute;
    z-index: 1;

    span {
      @include font-size(42);
    }

    &:focus,
    &:hover {
      @extend .text-theme-primary;

      box-shadow: none;
      opacity: 1;
      outline: none;
    }
  }
}

/* ==========================================================================
Navbar Modal
========================================================================== */
.navbar .modal {
  z-index: inherit !important;

  .modal-dialog {
    margin-top: calc(#{$navbar-height} + #{$spacer});
  }

  @include media-breakpoint-up(lg) {
    .modal-dialog {
      margin-top: calc(100px + #{$spacer * 3});
    }
  }
}
