/* ==========================================================================
Text quotes
========================================================================== */
.text-quotes {
  @extend
    .py-5;

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  h2 {
    @extend
      .mb-lg-3;
  }
}

/* ==========================================================================
Quotes card
========================================================================== */
.quote-card {
  @extend
    .p-3;

  border-radius: 10px;

  &:not(:last-of-type) {
    @extend
      .mb-4,
      .mb-lg-2;
  }
}

/* ==========================================================================
Quotes card content
========================================================================== */
.quote-card__content {
  @extend
    .d-flex,
    .flex-column,
    .flex-xl-row;
}

/* ==========================================================================
Quotes card text
========================================================================== */
// .quote-card__text {}

/* ==========================================================================
Quotes card image
========================================================================== */
.quote-card__image {
  @extend
    .align-self-end,
    .flex-shrink-0;

  max-width: 200px;

  img {
    @extend
      .mb-n3,
      .mt-sm-n5,
      .mt-xl-0;
  }
}

/* ==========================================================================
Quotes card quote
========================================================================== */
.quote-card__quote {
  font-weight: 700;
}

/* ==========================================================================
Quotes card name
========================================================================== */
.quote-card__name {
  @extend
    .d-flex,
    .flex-column,
    .flex-xl-row,
    .mb-0,
    .mt-auto;

  font-style: italic;

  span {
    @extend
      .mr-1;
  }
}

/* ==========================================================================
Text quotes dark
========================================================================== */
.text-quotes--dark {
  background-color: $custom-purple-1;
  color: $white;

  .quote-card {
    background-color: $white;
  }

  .quote-card__content {
    p {
      color: $custom-purple-1;
    }
  }
}

/* ==========================================================================
Text quotes light
========================================================================== */
.text-quotes--light {
  background-color: $white;
  color: $custom-purple-1;

  .quote-card {
    background-color: $custom-purple-1;
  }

  .quote-card__content {
    p {
      color: $white;
    }
  }
}

/* ==========================================================================
Text quotes left
========================================================================== */
.text-quotes--left {
  .col-quotes {
    @extend
      .order-lg-1;
  }

  .col-text {
    @extend
      .order-lg-2;
  }
}
