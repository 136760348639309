/* ==========================================================================
Hr check
========================================================================== */
.check__form {
  @extend
    .pt-6,
    .pt-lg-10;

  background-color: $theme-primary;
}

.check__form-title {
  text-align: center;

  h2 {
    color: $white;
  }
}

.check__form-text {
  @extend
    .pb-5,
    .pb-lg-8;

  text-align: center;

  p {
    color: $white;
  }
}

.input__group-wrapper {
  @extend
    .pb-5,
    .pb-lg-8;

  .radio__label {
    @extend
      .py-1,
      .px-2,
      .mr-1,
      .mx-md-1;

    cursor: pointer;
    position: relative;
    line-height: 2;
    height: 50px;
    text-align: center;
    width: 150px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: $white;
    }
  }

  .input__radio {
    cursor: pointer;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;

    &::before {
      content: "";
      border: 2px solid $white;
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 150px;
    }

    &::after {
      display: none;
    }
  }

  .input__radio:checked {
    &::after {
      display: block;
      border: 2px solid $white;
      background-color: $white;
      content: "";
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 150px;
      z-index: 0;
    }
  }

  .input__radio:checked ~ span {
    color: $theme-primary;
  }
}

.input__group-title {
  @extend .pb-2;

  h3 {
    color: $white;
  }
}

.input__group-btn {
  text-align: center;
}

@include media-breakpoint-up(md) {
  .input__group-wrapper {
    text-align: center;
  }
}

/* ==========================================================================
Hr check modal
========================================================================== */
.check__modal {
  .modal-content {
    border-radius: 10px;
  }

  .formidable {
    form[name="formidable_form"] {
      box-shadow: none !important;
      padding: 20px 0 0 !important;

      .btn {
        @extend .mb-1;

        float: right;
      }
    }
  }

  .close {
    cursor: pointer;

    .fa-times {
      color: $theme-primary;

      @include font-size(34);
    }
  }
}
