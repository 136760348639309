.text-block {
  background-color: $custom-purple-1;
  background-position: center;
  background-size: cover;
  color: $white;
  position: relative;

  &.has-image::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(37, 34, 91, 0.8) !important;
    z-index: 2;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }

  .container {
    position: relative;
    z-index: 3;
  }
}
