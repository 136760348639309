/* ==========================================================================
Card default swiper
========================================================================== */
.card__default-swiper {
  .card-default-pagination {
    @extend .d-lg-none;

    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
      background-color: rgba($theme-primary, 0.7);
      height: 10px;
      width: 10px;
    }

    .swiper-pagination-bullet-active {
      background-color: $theme-primary;
      height: 14px;
      width: 14px;
    }
  }
}
