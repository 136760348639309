/* ==========================================================================
Columns One
========================================================================== */
.columns__one-container {
  @extend
    .pt-4,
    .pb-10,
    .py-md-10;

  background-color: #3c3082;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  position: relative;

  .container {
    @extend
      .pb-10,
      .py-lg-10;

    position: relative;
    z-index: 2;
  }

  .row {
    @extend
      .pb-10,
      .pb-md-0,
      .mb-10,
      .mb-md-0,
      .py-xl-5,
      .px-1,
      .px-sm-0;
  }
}

.columns__image-desktop,
.columns__image-mobile {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.columns__image-desktop {
  @extend
    .d-none,
    .d-md-block;

  background-position: center;
}

.columns__image-mobile {
  @extend .d-md-none;

  background-position: 0;
}

.columns__one-content {
  @extend
    .pb-10,
    .pb-md-0;
}

.columns__one-title {
  @extend
    .mb-md-3,
    .mb-lg-4;

  h2 {
    color: $white;

    @include font-size(80);
  }
}

.columns__one-subtitle {
  @extend
    .mb-1,
    .mb-lg-3;

  h3 {
    @extend .h5;

    color: $white;

    @include font-size(32);
  }
}

.columns__one-button {
  @extend .mt-3;
}

.columns_right {
  justify-content: flex-end;
}

@include media-breakpoint-up(md) {
  .columns__one-title {
    h2 {
      color: $white;

      @include font-size(62);
    }
  }
}
