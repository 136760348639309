/* ==========================================================================
Table
========================================================================== */
.table-packages {
  background-color: $white;
  border: 0;
  border-left: 1px solid $theme-primary;
  border-collapse: collapse;
  width: 100%;

  th {
    @extend
      .py-1,
      .px-xxl-1;
  }

  tr,
  td {
    @extend .px-md-2;

    border-color: rgba($black, 0.1);
  }

  td {
    @extend
      .p-1,
      .px-md-2;

    position: relative;
    font-weight: 300;

    .fa-check,
    .fa-times {
      font-size: 22px;
    }

    .fa-check {
      color: $green;
    }

    .fa-times {
      color: rgba($black, 0.2);
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .table-heading {
    position: relative;
    text-align: center;

    h3 {
      color: rgba($theme-primary, 0.6);

      @include font-size(32);
    }

    span {
      @extend .h5;

      font-size: 18px;
      text-align: center;
    }

    &:nth-of-type(3) {
      border-left: 5px solid $theme-primary;
      border-right: 5px solid $theme-primary;
    }
  }

  .table-highlight {
    background-color: $green;
    border-radius: 5px;
    color: $white;
    position: absolute;
    top: -15px;
    right: 10px;
    padding: 3px 10px;
    font-family: $font-family-1;
  }

  .table-heading-content {
    @extend .py-md-3;
  }

  .table-subtitle {
    border: 0;

    p {
      @extend
        .h4,
        .pt-1,
        .mb-0;

      @include font-size(22);
    }
  }

  .table-item {
    @extend
      .px-1,
      .px-md-2;

    background-color: $gray-100;
    border-top: 1px solid rgba($black, 0.1);
    border-bottom: 1px solid rgba($black, 0.1);
    font-weight: 300;
    text-align: left;
  }
}

@include media-breakpoint-up(md) {
  .table-packages {
    .table-heading {
      background-color: $theme-primary;
      border: 0;

      h3 {
        @include font-size(25);
      }

      &:nth-of-type(3) {
        border-left: 5px solid $theme-primary;
        border-right: 5px solid $theme-primary;
      }
    }

    .table-heading-content {
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0 0 8px 2px rgba($black, 0.1);
      margin-bottom: -50px;
    }

    .table-highlight {
      top: -10px;

      @include font-size(20);
    }

    td {
      span {
        top: 57%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .table-packages {
    .table-heading {
      h3 {
        @include font-size(34);
      }
    }

    .table-subtitle {
      p {
        @include font-size(36);
      }
    }

    td {
      .fa-check,
      .fa-times {
        font-size: 24px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .table-packages {
    .table-heading {
      h3 {
        @include font-size(42);
      }
    }

    .table-highlight {
      right: 20px;
    }

    td {
      .fa-check,
      .fa-times {
        font-size: 28px;
      }
    }
  }
}
