/* ==========================================================================
Columns Two
========================================================================== */
.columns__container {
  @extend
    .py-5,
    .py-lg-8;

  position: relative;

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .columns__content {
    @extend
      .py-5,
      .py-lg-7;

    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;

    h2 {
      @extend .mb-1;

      text-transform: uppercase;

      @include font-size(62);
    }

    p {
      @extend .mb-0;
    }
  }

  .columns__image {
    position: relative;
    height: 100%;

    img {
      object-fit: cover;
      height: 250px;
      width: 100%;
    }
  }

  .image-right {
    .columns__content {
      @extend .order-lg-1;
    }

    .columns__image {
      @extend .order-lg-2;
    }
  }
}

.columns__button {
  .btn {
    @extend
      .mt-3;
  }
}

@include media-breakpoint-up(xxl) {
  .columns__container {
    .columns__image {
      img {
        object-fit: unset;
        height: auto;
      }
    }
  }
}
