/* ==========================================================================
Card vacancy
========================================================================== */
.card__vacancy {
  @extend
    .py-5;
}

.card__vacancy-title {
  @extend .pb-3;
}

.card__vacancy-item {
  @extend
    .px-2,
    .py-3,
    .mb-3;

  background-color: $theme-primary;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 340px;
  width: 100%;

  .card__title {
    h3 {
      color: $white;
    }
  }

  .card__categories {
    span {
      @extend
        .px-1,
        .my-1,
        .mr-1;

      border: 2px solid $white;
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .fa-map-marker-alt {
      margin-right: 3px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card__vacancy-item {
    min-height: 280px;
  }
}
