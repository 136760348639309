/* ==========================================================================
Video block
========================================================================== */
.video__block {
  position: relative;
  height: 60vh;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.video__block-swiper {
  height: 100%;
}

.video__block-inner {
  @extend .py-4;

  position: relative;
  height: 100%;
  width: 100%;

  &::before {
    @extend .w-100;

    background-color: rgba($theme-primary, 0.4) !important;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 2;
  }

  .container,
  .row {
    height: 100%;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .row {
    @extend
      .pb-10,
      .pb-md-0;

    display: flex;
    align-items: flex-end;
  }
}

.video__block-title {
  color: $white;
}

.video__block-subtitle {
  color: rgba($white, 0.5);
}

.video__block-play {
  @extend
    .mt-2,
    .mt-lg-4;

  font-family: $font-family-1;

  a {
    color: $white;
    text-transform: uppercase;

    @include font-size(24);

    .fa-play-circle {
      @extend
        .mr-1,
        .mr-lg-2;

      @include font-size(26);
    }

    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
    }
  }
}

.video__modal {
  background-color: rgba($black, 0.8);

  .modal-content {
    @extend .py-4;

    background-color: transparent;
    border: 0;
  }

  .video__modal-close {
    color: $white;
    cursor: pointer;
    top: 0;

    .fa-times {
      @include font-size(28);
    }

    &:hover,
    &:focus {
      color: $white !important;
    }
  }

  iframe {
    height: 400px;
  }
}

.video__swiper-btn {
  position: absolute;
  right: 20px;
  bottom: 30px;
  display: flex;
  height: 50px;

  .video-swiper-next,
  .video-swiper-prev {
    @extend .mr-3;

    color: $white;
    border: 2px solid $white;
    position: relative;
    text-align: center;
    height: 50px;
    width: 50px;

    @include transition();

    &::after {
      font-size: 20px;
      font-weight: bold;
      -webkit-text-stroke: 1px $white;
    }

    &:hover,
    &:focus {
      background-color: rgba($white, 0.3);
    }
  }
}

@include media-breakpoint-up(md) {
  .video__block-inner {
    .row {
      align-items: center;
    }
  }

  .video__block-title {
    @include font-size(72);
  }

  .video__swiper-btn {
    right: 50px;
    bottom: 50px;
  }
}

@include media-breakpoint-up(lg) {
  .video__block {
    height: 100vh;
  }

  .video__modal {
    iframe {
      height: 600px;
    }
  }

  .video__block-play {
    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .fa-play-circle {
        @include font-size(48);
      }

      span {
        margin-top: 5px;
      }
    }
  }

  .video__swiper-btn {
    right: 70px;
    bottom: 100px;
  }
}
