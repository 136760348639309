/* ==========================================================================
Card team pagination
========================================================================== */
.card-team-container {
  .next,
  .prev {
    position: relative;
    background-color: $white;
    color: $theme-primary;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid $gray-200;
    overflow: hidden;

    &::after {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-color: $white;
      border-radius: 4px;
      display: inline-block;
      position: absolute;
      right: 0;
      width: 50px;
      height: 50px;
      padding: 5px;
    }

    a {
      opacity: 0;
      z-index: 2;
    }
  }

  .next {
    &::after {
      background-image: url("/packages/enorm_blackbox/themes/enorm/assets/images/chevron-right.svg");
    }
  }

  .prev {
    &::after {
      background-image: url("/packages/enorm_blackbox/themes/enorm/assets/images/chevron-left.svg");
    }
  }
}
