/* ==========================================================================
Card banner
========================================================================== */
.card-banner {
  @extend
    .flex-fill,
    .mb-6,
    .mb-lg-10;

  background: none;
  border: 0;
  border-radius: 0;

  .card-img {
    object-fit: cover;
    border-radius: 0;
    min-height: 500px;
  }

  .card-img-overlay {
    @extend .p-md-10;

    h3,
    h5 {
      @extend .text-white;
    }
  }

  .card-text {
    @extend .text-white;
  }
}

.card-banner-btn {
  @extend
    .btn,
    .btn-lg,
    .btn-theme-primary,
    .mt-3;
}
