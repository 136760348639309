/* ==========================================================================
Info
========================================================================== */
.info__block {
  @extend
    .py-6,
    .py-lg-10;

  background-color: $theme-primary;
  color: $white;
  text-align: center;
}

.info__title {
  color: $white;
}

.info__text,
.info__button {
  @extend .pt-2;
}
