/* ==========================================================================
Footer
========================================================================== */
.footer {
  @extend
    .pb-3,
    .pt-5,
    .pt-md-10;

  background-color: $black;
}

/* ==========================================================================
Footer Column
========================================================================== */
.footer__column {
  @extend
    .mt-5,
    .pt-5;

  border-top: 1px solid $white;
}

/* ==========================================================================
Footer Nav
========================================================================== */
.footer__nav {
  @extend .flex-row;

  .nav-item {
    @extend .ml-3;
    @extend .mr-0;
  }

  .nav-link {
    @extend
      .d-block,
      .text-white;

    @include font-size(20);

    &::after {
      border-color: $white !important;
    }
  }
}

/* ==========================================================================
Copyright
========================================================================== */
.copyright {
  color: rgba($white, 0.6);
  text-align: center;

  p > a,
  .link {
    color: rgba($white, 0.6);
    text-decoration: underline;
  }
}
