/* ==========================================================================
Page List Plus
========================================================================== */
.ccm-block-page-list-wrapper {
  .plp_form_loading {
    @extend .mt-5;
    @extend .mx-auto;

    color: $theme-primary;
    display: none;
    height: 3rem;
    left: 0;
    position: absolute;
    right: 0;
    width: 3rem;
    z-index: 2;
  }
}

/* ==========================================================================
ccm-block-page-list-form
========================================================================== */
.ccm-search-block-form {
  .btn {
    @extend .btn-theme-primary;
  }
}

/* ==========================================================================
ccm-block-page-list-header
========================================================================== */
.ccm-block-page-list-header {
  @extend
    .col-12,
    .col-sm-auto,
    .mb-5;

  h2 {
    @extend
      .mb-0,
      .text-theme-primary;
  }
}

/* ==========================================================================
ccm-block-page-list-see-all
========================================================================== */
.ccm-block-page-list-see-all {
  @extend
    .align-items-end,
    .col-12,
    .col-sm,
    .d-flex,
    .justify-content-end,
    .text-right;

  .btn {
    @extend
      .p-0,
      .text-theme-primary;
  }
}

/* ==========================================================================
ccm-block-page-list-entry-thumbnail
========================================================================== */
.ccm-block-page-list-page-entry-thumbnail {
  @extend .mb-3;

  border-radius: 0;
  height: 250px;
  width: 100%;
  object-fit: cover;

  @include media-breakpoint-down(xl) {
    height: 200px;
  }

  @include media-breakpoint-down(md) {
    height: 150px;
  }

  @include media-breakpoint-down(sm) {
    height: 250px;
  }
}

/* ==========================================================================
ccm-block-page-list-date
========================================================================== */
.ccm-block-page-list-date {
  @extend .text-uppercase;
}

/* ==========================================================================
ccm-block-page-list-page-entry-read-more
========================================================================== */
.ccm-block-page-list-page-entry-read-more {
  &:focus,
  &:hover {
    color: $black;
  }
}

/* ==========================================================================
ccm-block-page-list-pages
========================================================================== */
.ccm-block-page-list-pages {
  .ccm-block-page-list-page {
    p {
      color: $black;
    }

    .card-title {
      @extend .h5;

      color: $theme-primary;
    }

    .card {
      @extend
        .flex-fill,
        .mb-3;

      border: 0;
      border-radius: 0;
    }

    .card-body {
      padding: 0;
    }

    .card-footer {
      @extend
        .mt-3,
        .p-0;

      background: none;
      border-top: 0;

      .btn {
        color: $gray-600;
        padding: 0;
      }
    }

    &:hover {
      text-decoration: none;

      .btn {
        color: $theme-primary;
      }
    }
  }

  div[class^="col"] {
    @extend .d-flex;
  }
}
