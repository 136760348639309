/* ==========================================================================
Offices List
========================================================================== */
.offices__list {
  .offices__item {
    @extend
      .col-6,
      .col-sm-4,
      .col-xl,
      .mb-3;

    .card {
      background: none;
      border: 0;

      .card-body {
        @extend .p-0;
      }

      .card-title {
        @extend .text-white;
      }

      .card-text {
        @extend .text-white;
      }
    }
  }
}

/* ==========================================================================
Offices Overview
========================================================================== */
.offices__overview {
  @extend .justify-content-center;

  .offices__item {
    @extend
      .col-12,
      .col-md-6,
      .d-flex,
      .justify-content-center,
      .mb-5;

    &:nth-child(2n) {
      @extend .justify-content-md-end;
    }

    .media {
      @extend .align-items-center;

      img {
        @extend .col-6;
      }
    }

    .media-body {
      @extend
        .col-6,
        .text-black;
    }
  }
}
/* stylelint-disable */
.offices__item {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
/* stylelint-enable */
