/* ==========================================================================
Plans
========================================================================== */
.plans__block {
  @extend .py-8;

  background-color: $theme-primary;
  display: none;
}

.plans__title,
.plans__text {
  color: $white;
  text-align: center;

  h2 {
    color: $white;
  }
}

.plans__text {
  @extend .py-2;
}

.plans__btn {
  text-align: center;
}

.plans__row {
  @extend .pt-7;

  align-items: center;
}

.col__plans {
  @extend
    .order-2;
}

.plans__item {
  @extend
    .pt-3,
    .px-2,
    .pb-2,
    .mb-4;

  background-color: $white;
  border-radius: 10px;
}

.plans__item-title {
  color: rgba($black, 0.5);
  font-family: $font-family-1;

  @include font-size(20);
}

.plans__item-btn {
  text-align: right;
}

.plans__item-option {
  @extend .mb-2;

  p {
    @extend .mb-0;
  }

  a {
    text-decoration: underline;
  }

  .fa-check-square {
    @extend .mr-1;

    color: $green;

    @include font-size(20);
  }

  &.disabled {
    cursor: default;
    opacity: 0.3;
    pointer-events: none;

    .fa-check-square {
      color: $black;
    }
  }
}

.best__choice {
  @extend
    .p-1;

  display: none;
  background-color: $green;
  border-radius: 10px;
  color: $white;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-family: $font-family-1;
  text-align: center;
  width: 65%;

  @include font-size(24);
}

.col__best-choice {
  @extend
    .order-1,
    .order-lg-2;
}

.plans__best-choice {
  @extend
    .pt-8;

  border: 4px solid $green;

  .best__choice {
    display: block;
  }
}

.option__title {
  @include font-size(18);
}
