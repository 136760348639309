/* ==========================================================================
YouTube Video
========================================================================== */
.youtube-video {
  position: relative;
  height: 250px;

  .fa-play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
  }

  @include media-breakpoint-up(sm) {
    height: 430px;
  }
}

.youtubeBlock {
  border: 0;

  .youtube-player {
    height: 220px;
  }
}

@include media-breakpoint-up(md) {
  .youtubeBlock {
    .youtube-player {
      height: 400px;
    }
  }
}

.youtube-video__poster {
  @extend
    .h-100,
    .w-100;

  object-fit: cover;
  object-position: center;
}

.youtube-video__play {
  @extend .d-block;
}

.youtube-video--large {
  position: relative;

  &::before {
    @extend .w-100;

    background-color: rgba($black, 0.4) !important;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 2;
  }
}

@include media-breakpoint-up(lg) {
  .youtube-video--large {
    height: 750px;
  }
}
