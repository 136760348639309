/* ==========================================================================
Typography
========================================================================== */
html,
body {
  font-family: $font-family-2;
}

h1,
.h1 {
  @extend .text-uppercase;

  @include font-size(80);

  color: $theme-primary;
  font-family: $font-family-1;
  font-weight: 700;
  line-height: 0.9;

  @include media-breakpoint-up(md) {
    @include font-size(90);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(100);
  }
}

h2,
.h2 {
  @extend .text-uppercase;

  @include font-size(48);

  color: $theme-primary;
  font-family: $font-family-1;
  font-weight: 700;
  line-height: 0.9;

  @include media-breakpoint-up(sm) {
    @include font-size(54);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(64);
  }
}

h3,
.h3 {
  @extend .text-uppercase;

  @include font-size(42);

  color: $theme-primary;
  font-family: $font-family-1;
  font-weight: 700;
  line-height: 32px;

  @include media-breakpoint-up(sm) {
    @include font-size(42);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(42);
  }
}

h4,
.h4 {
  @extend .text-uppercase;

  @include font-size(36);

  color: $theme-primary;
  font-family: $font-family-1;
  font-weight: 700;

  @include media-breakpoint-up(sm) {
    @include font-size(36);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(36);
  }
}

h5,
.h5 {
  @extend .text-uppercase;

  @include font-size(26);

  color: $theme-primary;
  font-family: $font-family-1;
  font-weight: 700;

  @include media-breakpoint-up(sm) {
    @include font-size(32);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(32);
  }
}

h6,
.h6 {
  @include font-size(22);

  font-family: $font-family-2;
  font-weight: 300;
}

p,
li {
  font-weight: 400;
  line-height: 28px;

  &.p-sm {
    @include font-size(14);
  }

  &.p-lg {
    @include font-size(18);
  }
}

small {
  @include font-size(12);
}

.jumbo {
  @extend .text-uppercase;

  @include font-size(72);

  color: $theme-primary;
  font-family: $font-family-1;
  font-weight: 700;
  line-height: 0.9;

  @include media-breakpoint-up(sm) {
    @include font-size(86);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(120);
  }
}
