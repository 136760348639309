.share-links-block {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.share-links {
  background-color: $theme-primary;
  border-radius: 0 0 15px 15px;
  color: $white;
  display: inline-flex;
  align-items: center;
  padding: 20px;

  span {
    font-family: $font-family-1;
    font-size: 26px;
    line-height: 26px;
    text-transform: uppercase;
    margin-right: 25px;
    margin-top: 5px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      list-style-type: none;
    }

    li:first-of-type {
      margin-right: 16px;
    }
  }

  a {
    color: $white;
    text-decoration: none;

    @include transition();

    img {
      height: 25px;
      width: 35px;
      object-fit: contain;
    }
  }
}
