/* ==========================================================================
Navbar
========================================================================== */
.navbar {
  @extend
    .fixed-top,
    .py-0;

  height: $navbar-height;
  transition: all 0.3s ease-in-out;
}

/* ==========================================================================
Navbar-nav
========================================================================== */
.navbar-nav {
  @extend .mr-auto;
  @extend .ml-3;

  .nav-link {
    @extend
      .d-inline,
      .p-0,
      .position-relative,
      .text-uppercase;

    @include font-size(42);

    color: $white;
    font-family: $font-family-1;
    line-height: 1.875rem;
    transition: color 0.3s ease-in-out;

    &::after {
      @extend .position-absolute;

      border-bottom: 2px solid $white;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      right: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .svg-inline--fa {
      @extend
        .d-none,
        .d-lg-inline-block;

      margin-left: 5px;

      @include font-size(16);
    }

    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;

      .svg-inline--fa {
        margin-left: 5px;
        transform: rotate(180deg);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  .nav-last {
    @extend
      .mt-2,
      .mt-lg-0;

    margin-right: 0 !important;

    .nav-link {
      @extend
        .position-relative,
        .px-2;

      background-color: $white;
      border-radius: 5px;
      color: $theme-primary;
      padding-top: 15px !important;
      padding-bottom: 11px !important;

      @include font-size(32);

      &:focus,
      &:hover {
        &::after {
          @extend .d-none;
        }
      }
    }
  }

  .nav-item {
    margin: $spacer * 1 0;
    padding-top: 5px;
    padding-bottom: 5px;

    &.active {
      .nav-link::after {
        opacity: 1;
      }
    }
  }
}

.navbar__items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar__login {
  @extend .pl-2;

  position: relative;
  z-index: 3;

  .navbar__login-link {
    color: $white;

    .fa-user {
      @include font-size(20);
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-nav {
    margin-left: auto !important;
    margin-right: 0 !important;

    .nav-item {
      margin: 0 $spacer * 3 0 0;
    }

    .nav-link {
      @include font-size(21);

      &::after {
        bottom: 0;
      }
    }

    .nav-last {
      .nav-link {
        @include font-size(21);
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .navbar-nav {
    .nav-last {
      .nav-link {
        min-width: 75px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar-nav {
    .nav-link {
      @include font-size(24);
    }

    .nav-last {
      .nav-link {
        @include font-size(24);
      }
    }
  }
}

/* ==========================================================================
Navbar-brand
========================================================================== */
.navbar-brand {
  @extend .m-0;
  @extend .p-0;

  width: 80px;
  z-index: 2;

  @include media-breakpoint-up(sm) {
    width: 120px;
  }

  @include media-breakpoint-up(xl) {
    width: 150px;
  }
}
