/* ==========================================================================
Card heading
========================================================================== */
.card-heading {
  @extend .flex-fill;

  background: none;
  border: 0;
  border-radius: 0;
}

/* ==========================================================================
Card heading inline
========================================================================== */
.card-heading-inline {
  .card-heading-col-left {
    @extend
      .col-12,
      .col-lg-3;
  }

  .card-heading-col-right {
    @extend
      .col,
      .pl-lg-5;
  }
}

/* ==========================================================================
Card heading block
========================================================================== */
.card-heading-block {
  .row {
    @extend
      .align-items-center,
      .justify-content-center,
      .text-center;
  }

  .card-heading-col-left {
    @extend .col-12;
  }

  .card-heading-col-right {
    @extend
      .col-12;
  }
}
