/* ==========================================================================
Card large
========================================================================== */
.card-large--list {
  .card-large {
    cursor: pointer;

    .ccm-block-page-list-page-entry-thumbnail {
      max-height: 500px;
    }

    .card-text {
      color: $black;
    }

    .card-title {
      @extend
        .h5,
        .text-theme-primary;
    }

    .card {
      @extend
        .flex-fill,
        .mb-5;

      background: none;
      border: 0;
      border-radius: 0;
    }

    .card-body {
      @extend .p-0;
    }

    .card-footer {
      @extend
        .mt-3,
        .p-0;

      background: none;
      border-top: 0;

      .btn {
        @extend
          .p-0;

        color: $gray-700;
      }
    }

    &:hover {
      text-decoration: none;

      .card-title {
        text-decoration: underline;
      }

      .btn {
        color: $theme-primary;
      }
    }
  }
}
