/* ==========================================================================
Columns Two Video
========================================================================== */
.columns__video-container {
  @extend
    .py-5,
    .py-lg-8;

  position: relative;

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .columns__content {
    @extend
      .py-5,
      .py-lg-7;

    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;

    h2 {
      @extend .mb-1;

      text-transform: uppercase;

      @include font-size(62);
    }

    p {
      @extend .mb-0;
    }
  }

  .columns__image {
    position: relative;
    height: 100%;

    img {
      object-fit: cover;
      height: 450px;
      width: 100%;
    }

    .fa-play-circle {
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 2;

      @include font-size(52);
    }
  }

  .columns__link {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;

    &::before {
      @extend .w-100;

      background-color: rgba($theme-primary, 0.2) !important;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 2;
    }
  }

  .video-right {
    .columns__content {
      @extend .order-lg-1;
    }

    .columns__image {
      @extend .order-lg-2;
    }
  }
}

.columns_video-btn {
  .btn {
    @extend
      .mt-3;
  }
}

@include media-breakpoint-up(xxl) {
  .columns__video-container {
    .columns__image {
      img {
        height: 625px;
      }
    }
  }
}

/* ==========================================================================
Columns Two Video Modal
========================================================================== */
.columns__modal {
  background-color: rgba($black, 0.8);

  .modal-content {
    @extend .py-4;

    background-color: transparent;
    border: 0;
  }

  .columns__modal-close {
    color: $white;
    cursor: pointer;
    top: 0;

    .fa-times {
      @include font-size(28);
    }

    &:hover,
    &:focus {
      color: $white !important;
    }
  }

  iframe {
    height: 400px;
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .columns__modal {
    iframe {
      height: 600px;
    }
  }
}
