/* ==========================================================================
Card team
========================================================================== */
.card-team-container {
  .custom-control-label {
    border-color: $gray-300;
    border-radius: 6px !important;
    font-size: 22px !important;
    width: 100%;
  }

  .custom-checkbox {
    @extend .pr-1;
  }

  .form-group {
    @extend .mb-0;
  }

  .btn-reset {
    @extend .ml-xl-1;

    line-height: 30px;
  }

  .ccm-search-block-form {
    @extend .mb-4;
  }
}

.card-team {
  @extend
    .overflow-hidden;

  height: 220px;

  @include media-breakpoint-up(sm) {
    height: 280px;
  }

  @include media-breakpoint-up(xl) {
    height: 230px;
  }

  @include media-breakpoint-up(xxl) {
    height: 228px;
  }

  .card-img {
    @extend
      .h-100,
      .mb-0,
      .mx-auto,
      .position-absolute;

    bottom: 0;
    left: 0;
    max-height: none;
    max-width: 300px;
    object-fit: cover;
    object-position: bottom;
    right: 0;
    transition: filter 0.3s ease-in-out;
  }

  .card-img-overlay {
    @extend
      .align-items-end,
      .d-flex,
      .p-0;

    background: linear-gradient(transparent, rgba($theme-primary, 0.4));

    @include transition();
  }

  &.card-team-link {
    &:hover,
    &:focus {
      .card-img-overlay {
        background: linear-gradient(transparent, rgba($theme-primary, 0.6));
      }
    }
  }

  .card-body {
    padding: 5px 9px !important;
  }

  .card-title {
    @extend
      .text-white;

    font-size: 20px !important;
    line-height: 20px !important;

    @include media-breakpoint-up(md) {
      font-size: 24px !important;
      line-height: 24px !important;
    }

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }

  .card-subtitle {
    @extend
      .text-uppercase,
      .text-white;

    @include font-size(16);

    font-family: $font-family-1;
    letter-spacing: 0.5px;

    @include media-breakpoint-up(md) {
      @include font-size(17);
    }
  }

  @include media-breakpoint-up(sm) {
    .card-img {
      max-width: none;
    }
  }
}

.card-team-pagination {
  .pagination {
    li {
      a {
        background-color: transparent;
        border: 0;
        font-weight: bold;
        height: 50px;
        line-height: 35px;
        color: rgba($black, 0.4);

        @include transition();

        &:hover,
        &:focus {
          color: $theme-primary;
        }
      }
    }

    .active {
      span {
        background-color: transparent;
        border: 0;
        color: $theme-primary;
        height: 50px;
        line-height: 35px;
        font-weight: bold;
      }
    }
  }
}
