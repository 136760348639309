/* ==========================================================================
Form background
========================================================================== */
.form-background {
  @extend
    .py-8,
    .mt-10;

  background-image: url("../../images/banner.jpeg");
  background-repeat: no-repeat;
  background-size: cover;

  .please_wait_loader {
    display: none;
  }
}

.background-full {
  background-image: url("../../images/form-background.jpg");

  .btn {
    @extend .mb-0;

    float: right;
  }
}
