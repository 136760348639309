.block-list {
  display: flex;
  margin: 0;
  padding: 0;

  li {
    color: $white;
    list-style-type: none;
    margin-right: 15px;
    padding: 10px;
    background-color: rgba($white, 0.45);
  }

  li:last-of-type {
    margin-right: 0;
  }
}
