/* ==========================================================================
Contact
========================================================================== */
.contact {
  @extend
    .py-10;

  background-color: $custom-purple-3;
  position: relative;

  h2 {
    @extend
      .mb-1;

    @include font-size(42px);
  }

  .btn.btn-link {
    @extend
      .mr-2,
      .p-0;

    @include transition();

    color: $custom-purple-1;
    box-shadow: none !important;
    font-family: $font-family-2;
    font-size: 1rem;
    font-weight: 400 !important;
    text-decoration: none;
    text-transform: none !important;

    &:focus,
    &:hover {
      color: $primary;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .fas {
    @extend
      .mr-1;
  }

  .formidable {
    form[name=formidable_form] {
      @extend
        .p-25;

      border-radius: 10px;
    }

    textarea {
      height: 125px;
    }

    .dz-details {
      @extend
        .p-0,
        .pb-1;
    }

    .dz-message {
      @extend
        .p-0;
    }

    .dz-preview {
      @extend
        .m-0;

      min-height: auto;
      width: 100%;

      .dz-details {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        top: auto;
      }
    }

    .dz-remove {
      @extend
        .btn-outline-black;
    }

    .file_upload {
      @extend
        .p-2;

      background-color: rgba($black, 0.05);
      border-radius: 5px;
      border: 0;
    }

    .dz-error-mark,
    .dz-image,
    .dz-success-mark,
    .dz-size,
    .help-block,
    .message.hide {
      @extend
        .d-none;
    }
  }

  .form__button {
    .input {
      @extend
        .mb-0;

      text-align: right;
    }
  }
}

@include media-breakpoint-up(lg) {
  .contact {
    background-color: $custom-purple-2;
  }
}

/* ==========================================================================
Contact image
========================================================================== */
.contact__image {
  @extend
    .d-lg-none,
    .text-center;
}

/* ==========================================================================
Contact content
========================================================================== */
.contact__content {
  @extend
    .p-2;

  background-color: $white;
  border-radius: 10px;

  .svg-inline--fa,
  a {
    color: $theme-primary;
  }

  tr {
    display: flex;
    flex-direction: column;
  }
}

@include media-breakpoint-up(xl) {
  .contact__content {
    tr {
      flex-direction: row;
    }
  }
}

/* ==========================================================================
Contact background
========================================================================== */
.contact__background {
  @extend
    .d-none,
    .d-lg-block;

  bottom: 0;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;

  img {
    height: 100%;
    object-fit: cover;
  }
}
