/* ==========================================================================
Search Form
========================================================================== */
.search__form {
  @extend
    .ml-lg-1;
}

.search__form-toggle {
  background: none !important;
  border: 0 !important;

  &:focus,
  &:hover {
    box-shadow: none !important;
    outline: none !important;
  }
}

/* ==========================================================================
Search Results
========================================================================== */
.search__results {
  .card {
    border-radius: 0;
  }

  .card-title {
    @extend .text-theme-primary;
  }

  .ccm-pagination-wrapper {
    @extend
      .d-flex,
      .justify-content-center,
      .mt-5;
  }
}
