/* ==========================================================================
Switch language list
========================================================================== */
.language__switch-flags {
  span {
    color: $white;
    padding: 0 3px;
  }

  ul {
    @extend
      .d-flex,
      .justify-content-center,
      .mb-0;

    list-style: none !important;
    padding: 0;
  }

  li {
    @extend
      .d-flex,
      .align-items-center;

    &:last-child {
      .language__switch-divider {
        display: none;
      }
    }
  }

  a {
    @extend
      .text-white,
      .text-uppercase;

    text-decoration: none;
    opacity: 0.8;

    @include font-size(14px);
    @include transition();

    &:hover {
      @extend .text-theme-primary;
    }
  }

  .ccm-block-switch-language-active-flag {
    opacity: 1;
  }
}
